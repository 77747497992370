import "./src/global/style.css";

export const onRouteUpdate = () => {
  const enablePostHog = process.env.GATSBY_ENABLE_POSTHOG === "true";

  if (window.posthog && enablePostHog) {
    window.posthog.capture("$pageview");
  }
};

// export const onClientEntry = () => {
//   const enablePostHog = process.env.GATSBY_ENABLE_POSTHOG === "true";

//   if (enablePostHog) {
//     posthog.init(process.env.GATSBY_POSTHOG_API_KEY, {
//       api_host: "https://theswiter.com/postIng",
//       ui_host: "https://eu.posthog.com",
//       capture_pageview: false,
//       capture_pageleave: true,
//     });
//     posthog.register({ source: "WEB" });
//   }
// };
